.leaflet-popup-content {
    margin: 0;
    width: auto !important;
}

.leaflet-popup-content-wrapper {
    padding: 0;
}

.company-card {
    border: none;
}

.company-card .card-header {
    padding: 10px;
}

.company-card .card-body {
    padding: 15px;
}

.company-card .card-footer {
    padding: 10px;
    text-align: center;
}

.company-card .btn-primary {
    width: 100%;
}
